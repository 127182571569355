var _dec, _dec2, _obj;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import discourseComputed from "discourse-common/utils/decorators";
import ComboBoxSelectBoxHeaderComponent from "select-kit/components/combo-box/combo-box-header";
export default ComboBoxSelectBoxHeaderComponent.extend((_dec = discourseComputed("selectedContent.color"), _dec2 = discourseComputed("selectedContent.text_color"), (_obj = {
  classNames: ["category-drop-header"],
  categoryBackgroundColor(categoryColor) {
    return categoryColor || "#e9e9e9";
  },
  categoryTextColor(categoryTextColor) {
    return categoryTextColor || "#333";
  }
}, (_applyDecoratedDescriptor(_obj, "categoryBackgroundColor", [_dec], Object.getOwnPropertyDescriptor(_obj, "categoryBackgroundColor"), _obj), _applyDecoratedDescriptor(_obj, "categoryTextColor", [_dec2], Object.getOwnPropertyDescriptor(_obj, "categoryTextColor"), _obj)), _obj)));