var _class;
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { concat } from "@ember/helper";
import { htmlSafe } from "@ember/template";
import { modifier } from "ember-modifier";
import concatClass from "discourse/helpers/concat-class";
import TrapTab from "discourse/modifiers/trap-tab";
import DFloatPortal from "float-kit/components/d-float-portal";
import { getScrollParent } from "float-kit/lib/get-scroll-parent";
import FloatKitApplyFloatingUi from "float-kit/modifiers/apply-floating-ui";
import FloatKitCloseOnClickOutside from "float-kit/modifiers/close-on-click-outside";
import FloatKitCloseOnEscape from "float-kit/modifiers/close-on-escape";
export default class DFloatBody extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "closeOnScroll", modifier(() => {
      const firstScrollParent1 = getScrollParent(this.trigger);
      const handler1 = () => {
        this.args.instance.close();
      };
      firstScrollParent1.addEventListener("scroll", handler1, {
        passive: true
      });
      return () => {
        firstScrollParent1.removeEventListener("scroll", handler1);
      };
    }));
  }
  get supportsCloseOnClickOutside() {
    return this.args.instance.expanded && this.options.closeOnClickOutside;
  }
  get supportsCloseOnEscape() {
    return this.args.instance.expanded && this.options.closeOnEscape;
  }
  get supportsCloseOnScroll() {
    return this.args.instance.expanded && this.options.closeOnScroll;
  }
  get trigger() {
    return this.args.instance.trigger;
  }
  get options() {
    return this.args.instance.options;
  }
}
_class = DFloatBody;
template(`
    <DFloatPortal
      @inline={{@inline}}
      @portalOutletElement={{@portalOutletElement}}
    >
      <div
        class={{concatClass
          @mainClass
          (if this.options.animated "-animated")
          (if @instance.expanded "-expanded")
        }}
        data-identifier={{this.options.identifier}}
        data-content
        aria-labelledby={{@instance.id}}
        aria-expanded={{if @instance.expanded "true" "false"}}
        role={{@role}}
        {{FloatKitApplyFloatingUi this.trigger this.options @instance}}
        {{(if @trapTab (modifier TrapTab autofocus=false))}}
        {{(if
          this.supportsCloseOnClickOutside
          (modifier FloatKitCloseOnClickOutside this.trigger @instance.close)
        )}}
        {{(if
          this.supportsCloseOnEscape
          (modifier FloatKitCloseOnEscape @instance.close)
        )}}
        {{(if this.supportsCloseOnScroll (modifier this.closeOnScroll))}}
        style={{htmlSafe (concat "max-width: " this.options.maxWidth "px")}}
        ...attributes
      >
        <div class={{@innerClass}}>
          {{yield}}
        </div>
      </div>
    </DFloatPortal>
  `, {
  component: _class,
  eval() {
    return eval(arguments[0]);
  }
});